.fuse-backdrop-on-mobile {
  @apply bg-black bg-opacity-60 sm:bg-transparent #{'!important'};
}

*[class*='mat-'],
*[class*='mat-mdc-'] {
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;

  * {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }
}

.mat-expansion-panel {
  margin-bottom: 24px;
  border-radius: 8px !important;
  transition: box-shadow 225ms cubic-bezier(0.4, 0, 0.2, 1);
  @apply shadow #{'!important'};

  &:last-child {
    margin-bottom: 0;
  }

  &.mat-expanded,
  &:hover {
    .mat-expansion-panel-header-title {
      color: var(--primary-color) !important;
    }

    @apply shadow-lg #{'!important'};
  }

  &:not(.mat-expanded) {
    .mat-expansion-panel-header {
      &:not([aria-disabled='true']) {
        &.cdk-keyboard-focused,
        &.cdk-program-focused,
        &:hover {
          // background: transparent !important;
        }
      }
    }
  }

  .mat-expansion-panel-header {
    font-size: 14px;

    &[aria-disabled='true'] {
      .mat-expansion-panel-header-description {
        margin-right: 28px;
      }
    }

    .mat-expansion-indicator {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;

      &:after {
        border-color: currentColor !important;
      }
    }
  }

  .mat-expansion-panel-body {
    @apply text-secondary #{'!important'};
  }
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  line-height: 1 !important;
  // --mat-mdc-button-persistent-ripple-color: transparent !important;

  .mdc-button__label {
    white-space: nowrap;
    position: relative;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 2 !important;
  }

  .mat-mdc-focus-indicator,
  .mat-ripple {
    z-index: 1;
    border-radius: 9999px;
  }

  &[disabled='true'] {
    .mat-icon {
      opacity: 0.38 !important;
    }
  }

  .mat-icon {
    margin: 0 !important;
    @apply icon-size-6;
  }
}

.mat-mdc-icon-button {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 40px !important;
  padding: 0 !important;

  svg,
  img {
    height: auto !important;
  }
}

.mat-mdc-fab {
  max-height: 56px;
  border-radius: 16px !important;

  &:not(.mdc-fab--extended) .mdc-fab__ripple {
    border-radius: 16px !important;
  }
}

.mat-mdc-mini-fab {
  border-radius: 12px !important;

  &:not(.mdc-fab--extended) .mdc-fab__ripple {
    border-radius: 12px !important;
  }
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 12px !important;
  border-radius: 8px !important;
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  & > .mat-icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  .mat-mdc-progress-spinner {
    .mdc-circular-progress__indeterminate-container {
      circle {
        stroke: currentColor !important;
        animation-duration: 6000ms;
      }
    }
  }
}

.mat-mdc-raised-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: theme('colors.gray.50') !important;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1) !important;

  .dark & {
    --mat-mdc-button-persistent-ripple-color: theme('colors.black') !important;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1) !important;
  }

  .mat-icon {
    color: currentColor !important;
  }

  .mat-ripple-element {
    @apply bg-black #{'!important'};
  }
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button {
  &:not([disabled='true']) {
    &.mat-primary {
      .mat-icon {
        @apply text-primary #{'!important'};
      }
    }

    &.mat-accent {
      .mat-icon {
        @apply text-secondary #{'!important'};
      }
    }

    &.mat-warn {
      .mat-icon {
        @apply text-warn #{'!important'};
      }
    }
  }
}

.mat-mdc-outlined-button {
  &.mat-primary {
    &:not([disabled='true']) {
      @apply border-primary dark:border-primary #{'!important'};
    }

    &[disabled='true'] {
      @apply border-gray-300 dark:border-gray-600 #{'!important'};
    }
  }
}

.mdc-button {
  .mdc-button__label {
    white-space: nowrap;
  }
}

.mat-button-toggle-group {
  border: none !important;
  @apply space-x-1;

  &.mat-button-toggle-group-appearance-standard {
    .mat-button-toggle + .mat-button-toggle {
      background-clip: padding-box;
    }
  }

  .mat-button-toggle {
    border-radius: 9999px;
    overflow: hidden;
    border: none !important;
    font-weight: 500;

    &.mat-button-toggle-checked {
      .mat-button-toggle-label-content {
        @apply text-default #{'!important'};
      }
    }

    .mat-button-toggle-label-content {
      padding: 0 20px;
      line-height: 40px !important;
      @apply text-secondary;
    }
  }
}

.mat-mdc-checkbox {
  display: inline-flex !important;

  .mdc-form-field {
    padding-right: 12px;
  }
}

// .mdc-checkbox__native-control {
//     opacity: 0 !important;
// }

.mat-mdc-chip {
  font-weight: 500 !important;
}

.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    border-radius: 16px !important;
    padding: 24px;
  }
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6) !important;
  position: fixed;
}

.mat-mdc-form-field.mat-form-field-appearance-fill {
  &.mat-form-field-disabled {
    opacity: 0.7 !important;
  }

  &.mat-form-field-invalid {
    .mat-mdc-text-field-wrapper {
      @apply border-warn dark:border-warn #{'!important'};
    }

    .mat-mdc-select {
      .mat-mdc-select-placeholder {
        @apply text-warn #{'!important'};
      }
    }
  }

  &:hover {
    .mat-mdc-form-field-focus-overlay {
      opacity: 0 !important;
    }
  }

  &.mat-focused {
    .mat-mdc-form-field-focus-overlay {
      opacity: 0 !important;
    }
  }

  &.mat-focused:not(.mat-form-field-invalid) {
    .mat-mdc-text-field-wrapper {
      @apply border-primary dark:border-primary #{'!important'};
    }
  }

  &.mat-mdc-form-field-type-mat-native-select {
    .mat-mdc-form-field-infix {
      select {
        top: auto;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-right: 18px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2364748B' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right -7px center;
        background-size: 24px;

        .dark & {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2397a6ba' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
        }
      }

      &:after {
        display: none;
      }
    }
  }

  .mat-mdc-text-field-wrapper {
    padding: 0;
    margin-bottom: 16px;

    &:not(.mdc-text-field--no-label) {
      margin-top: 24px;
      overflow: visible;
    }

    .mat-mdc-form-field-focus-overlay {
      border-radius: 6px;
    }

    .mat-mdc-form-field-flex {
      position: relative;
      display: flex;
      align-items: stretch;
      min-height: 34px;
      padding: 0 16px;
      border-radius: 6px;
      border-width: 1px;
      border-style: solid;
      @apply shadow-sm bg-white border-gray-300 dark:bg-black dark:bg-opacity-5 dark:border-gray-500 #{'!important'};

      .mat-mdc-form-field-icon-prefix,
      .mat-mdc-form-field-text-prefix {
        padding: 0 !important;

        > .mat-icon {
          margin-right: 12px;
          padding: 0 !important;
        }

        > .mat-mdc-icon-button {
          margin: 0 4px 0 -10px;
        }

        > .mat-mdc-select {
          margin-right: 10px;
        }

        > .mat-datepicker-toggle {
          margin-left: -8px;
        }
      }

      .mat-mdc-form-field-icon-suffix,
      .mat-mdc-form-field-text-suffix {
        padding: 0 !important;

        > .mat-icon {
          margin-left: 12px;
          padding: 0 !important;
        }

        > .mat-mdc-icon-button {
          margin: 0 -10px 0 4px;
        }

        > .mat-mdc-select {
          margin-left: 10px;
        }

        > .mat-datepicker-toggle {
          margin-right: -8px;
        }
      }

      .mat-mdc-form-field-icon-prefix,
      .mat-mdc-form-field-text-prefix,
      .mat-mdc-form-field-icon-suffix,
      .mat-mdc-form-field-text-suffix {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @apply text-hint #{'!important'};

        .mat-mdc-icon-button {
          width: 40px;
          min-width: 40px;
          height: 40px;
          min-height: 40px;
        }

        .mat-icon,
        .mat-mdc-icon-button:not([disabled]),
        .mat-mdc-select-value {
          @apply text-hint;
        }

        .mat-datepicker-toggle-default-icon {
          @apply icon-size-6;
        }

        .mat-mdc-select {
          display: flex;
          align-items: center;

          &:focus {
            .mat-mdc-select-trigger {
              .mat-mdc-select-value {
                @apply text-primary #{'!important'};
              }

              .mat-mdc-select-arrow-wrapper {
                .mat-mdc-select-arrow {
                  border-top-color: var(--primary-color) !important;
                }
              }
            }
          }

          .mat-mdc-select-trigger {
            display: flex;
            align-items: center;

            .mat-mdc-select-value {
              display: flex;
              max-width: none;
              mat-mdc-select-trigger {
                .mat-icon {
                  margin: 0 !important;
                }
              }
            }

            .mat-mdc-select-arrow-wrapper {
              display: flex;
              align-items: center;
              transform: none;
              margin-left: 4px;

              .mat-mdc-select-arrow {
                min-height: 0;
                @apply text-gray-500 dark:text-gray-400 #{'!important'};
              }
            }
          }
        }
      }

      .mat-mdc-form-field-infix {
        position: static;
        display: flex;
        align-items: center;
        width: 88px;
        min-height: 34px;
        padding: 0;
        border: 0;

        .mat-input-element {
          margin-top: 0;
        }

        .mat-mdc-floating-label {
          top: -25px !important;
          left: 0 !important;
          width: 100% !important;
          transform: none !important;
          pointer-events: auto;
          font-weight: 500 !important;
          @apply text-default #{'!important'};
        }

        textarea.mat-mdc-input-element {
          margin: 12px 0;
          padding: 0 6px 0 0;
        }

        .mat-mdc-chip-set {
          width: 100%;
          margin: 0 -8px;
        }
      }
    }

    .mdc-line-ripple {
      display: none;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
    font-size: 12px;
    font-weight: 500;

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding: 0;
    }

    .mat-mdc-form-field-hint {
      @apply text-hint #{'!important'};
    }
  }

  &:has(textarea.mat-mdc-input-element) {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-text-prefix,
        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-text-suffix {
          align-self: flex-start;
          padding-top: 14px !important;
        }
      }
    }
  }

  &.s-mat-rounded {
    .mat-mdc-text-field-wrapper {
      border-radius: 24px;
    }

    &.s-mat-emphasized-affix {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-icon-prefix,
          .mat-mdc-form-field-text-prefix {
            border-radius: 24px 0 0 24px;

            > .mat-icon {
              margin-right: 12px;
            }

            > .mat-mdc-icon-button {
              margin: 0 2px 0 -10px !important;
            }

            > .mat-mdc-select {
              margin-right: 8px;
            }

            > .mat-datepicker-toggle {
              margin-right: 4px;
            }

            > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(
                .mat-datepicker-toggle
              ) {
              margin-right: 12px;
            }
          }

          .mat-mdc-form-field-icon-suffix,
          .mat-mdc-form-field-text-suffix {
            border-radius: 0 24px 24px 0;

            > .mat-icon {
              margin-left: 12px !important;
            }

            > .mat-mdc-icon-button {
              margin: 0 -10px 0 2px !important;
            }

            > .mat-mdc-select {
              margin-left: 12px !important;
            }

            > .mat-datepicker-toggle {
              margin-left: 4px !important;
            }

            > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(
                .mat-datepicker-toggle
              ) {
              margin-left: 12px !important;
            }
          }
        }
      }
    }
  }

  &.s-mat-dense {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-text-prefix,
        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-text-suffix {
          .mat-mdc-icon-button {
            width: 32px !important;
            min-width: 32px;
            height: 32px;
            min-height: 32px;
          }
        }

        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-text-prefix {
          > .mat-mdc-icon-button {
            margin-left: -6px;
            margin-right: 12px;
          }
        }

        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-text-suffix {
          > .mat-mdc-icon-button {
            margin-left: 12px;
            margin-right: -6px;
          }
        }

        .mat-mdc-form-field-infix {
          min-height: 40px;

          textarea.mat-mdc-input-element {
            margin: 8px 0;
          }
        }
      }
    }

    &:has(textarea.mat-mdc-input-element) {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-icon-prefix,
          .mat-mdc-form-field-text-prefix,
          .mat-mdc-form-field-icon-suffix,
          .mat-mdc-form-field-text-suffix {
            padding-top: 10px !important;
          }
        }
      }
    }

    &.s-mat-rounded {
      .mat-mdc-text-field-wrapper {
        border-radius: 20px;
      }

      &.s-mat-emphasized-affix {
        .mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            .mat-mdc-form-field-icon-prefix,
            .mat-mdc-form-field-text-prefix {
              border-radius: 20px 0 0 20px !important;
            }

            .mat-mdc-form-field-icon-suffix,
            .mat-mdc-form-field-text-suffix {
              border-radius: 0 20px 20px 0 !important;
            }
          }
        }
      }
    }
  }

  &.s-mat-emphasized-affix {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-text-prefix {
          align-self: stretch !important;
          margin: 0 16px 0 -16px !important;
          padding-left: 16px !important;
          border-radius: 6px 0 0 6px;
          border-right-width: 1px;
          border-style: solid;

          > .mat-icon {
            margin-right: 16px;
          }

          > .mat-mdc-icon-button {
            margin: 0 6px 0 -10px !important;
          }

          > .mat-mdc-select {
            margin-right: 12px !important;
          }

          > .mat-datepicker-toggle {
            margin-right: 8px;
          }

          > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(
              .mat-datepicker-toggle
            ) {
            margin-right: 16px;
          }
        }

        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-text-suffix {
          align-self: stretch !important;
          margin: 0 -16px 0 16px !important;
          padding-right: 16px !important;
          border-radius: 0 6px 6px 0;
          border-left-width: 1px;
          border-style: solid;

          > .mat-icon {
            margin-left: 16px;
          }

          > .mat-mdc-icon-button {
            margin: 0 -10px 0 6px !important;
          }

          > .mat-mdc-select {
            margin: 0 -4px 0 16px !important;
          }

          > .mat-datepicker-toggle {
            margin-left: 8px;
          }

          > *:not(.mat-icon):not(.mat-mdc-icon-button):not(.mat-mdc-select):not(
              .mat-datepicker-toggle
            ) {
            margin-left: 16px;
          }
        }

        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-text-prefix,
        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-text-suffix {
          @apply bg-default border-gray-300 dark:border-gray-500 #{'!important'};
        }
      }
    }

    &:has(textarea.mat-mdc-input-element) {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-icon-prefix,
          .mat-mdc-form-field-text-prefix,
          .mat-mdc-form-field-icon-suffix,
          .mat-mdc-form-field-text-suffix {
            align-items: flex-start;
          }
        }
      }
    }
  }

  &.s-mat-bold {
    .mat-mdc-text-field-wrapper {
      border-width: 2px !important;
    }
  }
}

.mat-mdc-form-field.mat-form-field-appearance-outline {
  &.mat-form-field-invalid {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: var(--warn) !important;
    }
  }

  &.mat-focused:not(.mat-form-field-invalid) {
    &.mat-primary {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: var(--primary-color) !important;
      }
    }

    &.mat-accent {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: var(--secondary-color) !important;
      }
    }
  }

  &:not(.mat-focused):not(.mat-form-field-invalid) {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mdc-notched-outline {
          .mdc-notched-outline__leading,
          .mdc-notched-outline__notch,
          .mdc-notched-outline__trailing {
            @apply border-blue-300 dark:border-blue-500 #{'!important'};
          }
        }
      }
    }
  }

  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mdc-notched-outline {
        .mdc-notched-outline__notch {
          border-right-style: none !important;
        }
      }
    }
  }
}

.mat-icon {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  font-size: 24px;
  line-height: 24px;
  -webkit-appearance: none !important;
}

.mat-mdc-input-element {
  &::placeholder {
    transition: none !important;
  }

  &::-moz-placeholder {
    transition: none !important;
  }

  &::-webkit-input-placeholder {
    transition: none !important;
  }

  &:-ms-input-placeholder {
    transition: none !important;
  }
}

.mat-mdc-menu-panel {
  min-width: 144px !important;

  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      display: flex;
      align-items: center;

      > span {
        display: inline-flex;
        align-items: center;
      }

      &.mat-mdc-menu-item-submenu-trigger {
        padding-right: 40px;
      }

      .mat-icon {
        margin-right: 12px;
      }

      .mat-icon-no-color {
        --tw-text-opacity: 1;
        color: rgba(var(--s-mat-icon-rgb), var(--tw-text-opacity));
      }
    }

    mat-divider {
      margin: 8px 0;
    }
  }
}

.mat-mdc-paginator {
  .mat-mdc-paginator-container {
    padding: 0px 15px;
    height: 35px;
    justify-content: space-between;

    @screen sm {
      justify-content: normal;
    }

    .mat-mdc-paginator-page-size {
      align-items: center;
      min-height: 35px;
      margin: 5px;

      .mat-mdc-paginator-page-size-label {
        display: none;
        margin-right: 12px;

        @screen sm {
          display: block;
        }
      }

      .mat-mdc-paginator-page-size-select {
        margin: 0;

        .mat-mdc-text-field-wrapper {
          padding: 0 10px;

          .mat-form-field-flex {
            min-height: 32px;
          }
        }
      }
    }

    .mat-mdc-paginator-range-actions {
      margin: 8px 0;

      .mat-mdc-paginator-range-label {
        margin-right: 16px;
      }
    }
  }
}

.mat-mdc-select {
  display: inline-flex !important;
  .mat-mdc-select-placeholder {
    transition: none !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .mat-mdc-select-trigger {
    .mat-mdc-select-value {
      position: relative;
      display: flex;
      max-width: none;
      font-weight: 500 !important;
      .mat-mdc-select-value-text {
        display: inline-flex;
        font-weight: 500 !important;
        > * {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .mat-mdc-select-arrow-wrapper::before {
    transform: translateY(0) !important;
    content: url('../../../assets/icons/arrow-down.svg') !important;
    margin-top: 4px;
  }

  .mat-mdc-select-arrow {
    display: none;
  }
}

.mat-step-icon {
  background-color: var(--secondary-color) !important;
  color: var(--white) !important;
  .mat-icon {
    // color: currentColor !important;
  }
}

.mat-step-icon-selected {
  background-color: var(--primary-color) !important;
}

.mat-step-icon-state-edit {
  background-color: var(--primary-color) !important;
}

.mat-step-label,
.mat-step-label-selected {
  font-weight: 600 !important;
}

.mat-mdc-table {
  .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
    background: none !important;
  }
}

.mat-mdc-tab-group {
  &.s-mat-no-header {
    .mat-mdc-tab-header {
      height: 0 !important;
      max-height: 0 !important;
      border: none !important;
      visibility: hidden !important;
      opacity: 0 !important;
    }
  }

  &:not(.mat-background-primary):not(.mat-background-accent) {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        box-shadow: inset 0 -1px var(--fuse-border);
      }
    }
  }

  .mat-mdc-tab-header {
    .mat-mdc-tab-label-container {
      margin: 0 10px;
    }
  }

  .mat-mdc-tab-body-content {
    padding: 24px;
  }
}

textarea.mat-mdc-input-element {
  box-sizing: content-box !important;
}

.mat-toolbar {
  &.mat-primary {
    .mat-icon {
      @apply text-primary #{'!important'};
    }

    .text-secondary {
      @apply text-primary text-opacity-60 #{'!important'};
    }

    .text-hint {
      @apply text-primary text-opacity-38 #{'!important'};
    }

    .text-disabled {
      @apply text-primary text-opacity-38 #{'!important'};
    }

    .divider {
      @apply text-primary text-opacity-12 #{'!important'};
    }
  }

  &.mat-accent {
    .mat-icon {
      // @apply text-on-accent #{'!important'};
    }

    .text-secondary {
      // @apply text-on-accent text-opacity-60 #{'!important'};
    }

    .text-hint {
      // @apply text-on-accent text-opacity-38 #{'!important'};
    }

    .text-disabled {
      // @apply text-on-accent text-opacity-38 #{'!important'};
    }

    .divider {
      // @apply text-on-accent text-opacity-12 #{'!important'};
    }
  }

  &.mat-warn {
    .mat-icon {
      @apply text-warn #{'!important'};
    }

    .text-secondary {
      @apply text-warn text-opacity-60 #{'!important'};
    }

    .text-hint {
      @apply text-warn text-opacity-38 #{'!important'};
    }

    .text-disabled {
      @apply text-warn text-opacity-38 #{'!important'};
    }

    .divider {
      @apply text-warn text-opacity-12 #{'!important'};
    }
  }
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: var(--dark-color) !important;
  color: white;

  .dark & {
    background-color: var(--secondary-color) !important;
    color: var(--fuse-bg-default) !important;
  }
}

// Override for sidebar
.sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused,
.sidebar-mini.sidebar-collapse .main-sidebar:hover {
  margin-left: 0 !important;
  width: 5.2rem !important;
}

.link {
  color: #2196f3 !important;
}

// Sidebar icons
.nav-sidebar > .nav-item .nav-icon {
  margin-left: 0.05rem;
  font-size: 1.2rem;
  margin-right: 0.2rem;
  text-align: center;
  width: 1.6rem;
  display: inline-block;
  color: #ffffff;
  fill: #e7e7e7;
}

.mb-0 {
  .mat-mdc-form-field.mat-form-field-appearance-fill {
    margin-bottom: 0px !important;
  }

  .mat-mdc-text-field-wrapper {
    margin-bottom: 0px !important;
  }
}

button color fixes .mdc-button.mat-primary,
.mat-mdc-unelevated-button.mat-primary:hover,
.mat-mdc-raised-button.mat-primary:hover {
  color: #f0fff3 !important;
}

.mat-mdc-unelevated-button:not(:disabled, :hover).mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  color: #f0fff3 !important;
}

.mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: #fff !important;
  flex: 0 0 13px;
}

.mat-warn {
  --mdc-filled-button-container-color: var(--warn);
  --mdc-filled-button-label-text-color: #fff !important;
}

.mat-primary {
  --mdc-filled-button-container-color: var(--primary-color);
  --mdc-filled-button-label-text-color: #fff !important;
}

.mat-accent {
  --mdc-filled-button-container-color: var(--primary-color);
  --mdc-filled-button-label-text-color: #fff !important;
  accent-color: var(--primary-color) !important;
}

.mat-mdc-tab-body-wrapper {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.mat-mdc-tab-body-content {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}

// Adminlte override
label {
  display: inline-block;
  margin-bottom: 0.1rem !important;
}

.mdc-linear-progress__primary-bar {
  background-color: var(--primary-color) !important;
}

.mat-mdc-progress-bar,
.mdc-linear-progress {
  height: 5px !important;
}

// Chips
.mat-mdc-standard-chip:not(.mdc-evolution-chip--selected)
  .mdc-evolution-chip__action--primary:before {
  border-width: 0 !important;
}

// Mat stepper override
.mat-horizontal-stepper-header {
  height: 55px !important;
}

// Override all app font
body {
  font-family: 'Inter', sans-serif !important;
}

// Overide table font sizes
table.table * {
  font-size: inherit;
}

// Override the arrow for mat input dropdown fields. This is added since we are using lower height for the input fields than the angular material default size.
.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: translateY(-2px) !important;
}

.mat-mdc-slider {
  --mdc-slider-handle-color: var(--primary-color) !important;
  --mdc-slider-focus-handle-color: var(--primary-color) !important;
  --mdc-slider-hover-handle-color: var(--primary-color) !important;
  --mdc-slider-active-track-color: var(--primary-color) !important;
  --mdc-slider-inactive-track-color: var(--primary-color) !important;
  --mdc-slider-with-tick-marks-active-container-color: #000 !important;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--primary-color) !important;
  --mat-mdc-slider-ripple-color: var(--primary-color) !important;
  --mat-mdc-slider-hover-ripple-color: rgba(var(--primary-color), 0.05) !important;
  --mat-mdc-slider-focus-ripple-color: rgba(var(--primary-color), 0.2) !important;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: var(--primary-color) !important;
  --mdc-switch-selected-handle-color: var(--primary-color) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--primary-color) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--primary-color) !important;
  --mdc-switch-selected-focus-handle-color: var(--primary-color) !important;
  --mdc-switch-selected-hover-handle-color: var(--primary-color) !important;
  --mdc-switch-selected-pressed-handle-color: var(--primary-color) !important;
  --mdc-switch-selected-focus-track-color: var(--primary-color) !important;
  --mdc-switch-selected-hover-track-color: var(--primary-color) !important;
  --mdc-switch-selected-pressed-track-color: var(--primary-color) !important;
  --mdc-switch-selected-track-color: var(--primary-color) !important;
}

.mat-mdc-tab.mat-mdc-focus-indicator {
  color: var(--primary-color) !important;
}

// Override the material dropdown/mat select height. To use this big size put the s-mat-big class in the mat-form-field class
.s-mat-big.mat-mdc-form-field.mat-form-field-appearance-fill
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-flex {
  min-height: 44px !important;
}

.mdc-snackbar {
  background-color: var(--warn-color);
  border-radius: 0.5rem;
}

:not(.mat-pseudo-checkbox-full).mat-pseudo-checkbox-checked {
  display: none !important;
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.mat-mdc-radio-checked .mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--primary-color) !important;
}

.mat-mdc-radio-checked .mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--primary-color) !important;
}

.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-flex {
  border-width: 0px !important;
}

.mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper {
  margin-bottom: 0 !important;
  background-color: white !important;
  border-style: solid !important;
  border-color: var(--pale-color) !important;
  border-width: 2px !important;
  border-radius: 0.5rem !important;
  height: 2.75rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mat-mdc-form-field-type-mat-select .mat-select-search-inner-row {
  background: white !important;
}

.mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-flex {
  border-width: 0px !important;
}

.mat-mdc-form-field-type-mat-date-range-input .mat-mdc-text-field-wrapper {
  margin-bottom: 0 !important;
  background-color: white !important;
  border-style: solid !important;
  border-color: var(--pale-color) !important;
  border-width: 2px !important;
  border-radius: 0.5rem !important;
  height: 2.75rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mat-mdc-form-field-type-mat-date-range-input .mat-select-search-inner-row {
  background: white !important;
}

.mat-mdc-form-field-type-mat-input:has(input) .mat-mdc-form-field-flex {
  border-width: 0px !important;
}

.mat-mdc-form-field-type-mat-input:has(input) .mat-mdc-text-field-wrapper {
  margin-bottom: 0 !important;
  background-color: white !important;
  border-style: solid !important;
  border-color: var(--pale-color) !important;
  border-width: 2px !important;
  border-radius: 0.5rem !important;
  height: 2.75rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mat-mdc-form-field-type-mat-input:has(input) .mat-select-search-inner-row {
  background: white !important;
}
