// Override sidebar
.main-sidebar {
  min-height: 100% !important;
  position: fixed !important;
  top: 0;
  width: 78px;
  margin: 0;
  padding: 0;
}

.sidebar {
  margin: 0;
  padding: 0;
}

#sidebar-overlay {
  display: none !important;
}

.nav-sidebar > .nav-item .nav-icon {
  margin-left: 0;
}

.sidebar-collapse .main-sidebar,
.sidebar-collapse .main-sidebar::before {
  margin-left: 0 !important;
}

body.sidebar-open .content-wrapper,
body.sidebar-open .main-footer,
body.sidebar-open .main-header {
  margin-left: 78px !important;
}

@media (min-width: 392px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
    transition: margin-left 0.3s ease-in-out;
    margin-left: 4.8rem;
  }
}

@media (min-width: 392px) {
  .sidebar-mini.sidebar-collapse .main-sidebar,
  .sidebar-mini.sidebar-collapse .main-sidebar::before {
    margin-left: 0;
    width: 5.2rem;
  }
}
