/* Core */
// @import 'core/tailwind-config';

/* Overrides */
@import 'overrides/angular-material';
@import 'overrides/sidebar';

html {
  background: #f1f5f9;
}

[heyreachButton]:disabled {
  opacity: 50% !important;
  cursor: not-allowed !important;
}

[heyreachButton][buttonType='primary']:disabled {
  opacity: 100% !important;
  background-color: var(--pale-color) !important;
  color: var(--gray-color) !important;
  cursor: not-allowed !important;
}

.loading-invis {
  opacity: 0;
}

.loading-spinner {
  position: absolute !important;
}

ngx-material-timepicker-container {
  z-index: 10000;
}

.ngx-timepicker {
  height: 50px !important;
}

.nav-sidebar .nav-item > .nav-link {
  margin-bottom: 0.52rem;
}

.brand-link .brand-image {
  float: left;
  line-height: 0.8;
  margin-left: 0.3rem;
  margin-right: 0.5rem;
  margin-top: 0px;
  max-height: 44px;
  width: auto;
  margin-bottom: 25px;
  position: relative;

  &.collapsed {
    top: 0px;
    margin-left: 1.3rem;
  }
}

.no-scroll-body {
  overflow-y: hidden !important;
}

.main-footer {
  padding: 5px 1.8rem 5px 1.8rem;
}

.swal2-content {
  font-size: 1em;
}

.swal2-title {
  font-size: 1.2em;
}

.swal2-actions {
  font-size: 0.8em;
}

// Override zorro ng styles
address,
dl,
ol,
p,
pre,
ul {
  margin-bottom: inherit !important;
}

dd,
h1,
h2,
h3,
h4,
h5,
h6,
legend {
  margin-bottom: inherit !important;
}

.drawer-container {
  min-height: 100%;
  // max-height: 0px; this breaks scrolling in all pages
}

// Override image preview modal
.image-preview-dialog .mdc-dialog__container {
  position: relative;
  padding: 0;
  background: transparent !important;
  overflow: hidden !important;
  box-shadow: none !important;
}

.team-invite-dialog .mdc-dialog__container {
  min-width: 80%;
  width: 80%;
  position: relative;
  margin: 0 auto;
  padding: 0;
  background: transparent !important;
  overflow: hidden !important;
  box-shadow: none !important;
}

.team-invite-dialog {
  min-width: 64%;
  width: 64%;
  position: relative;
  margin: 0 auto;
  padding: 0;
  background: transparent !important;
  overflow: hidden !important;
  box-shadow: none !important;
}

.input-no-padding {
  .mat-mdc-text-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
}

// Override mat progress bar default background color
.mdc-linear-progress__buffer-bar {
  background-color: #e6e6e6 !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #777;
}

.mat-mdc-snack-bar-container {
  &.error-snackbar {
    --mdc-snackbar-container-color: var(--fuse-warn) !important;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.warning-snackbar {
    --mdc-snackbar-container-color: var(--warning-muted-color) !important;
    --mat-mdc-snack-bar-button-color: var(--warning-color);
    --mdc-snackbar-supporting-text-color: var(--warning-color);
  }
}

.mat-drawer.mat-drawer-end {
  position: fixed;
  overflow: auto;
  min-width: 550px !important;
}

.mat-calendar-body-in-range {
  background-color: var(--primary-color) !important;
  border-top: 2px solid var(--white) !important;
  border-bottom: 2px solid var(--white) !important;
}

.mat-calendar-body-range-start {
  border-radius: 50% 0 0 50%;
  border-left: 2px solid var(--white) !important;
}

.mat-calendar-body-range-end {
  border-radius: 0 50% 50% 0;
  border-right: 2px solid var(--white) !important;
}

:not(.mat-calendar-body-in-range).mat-calendar-body-active {
  background-color: var(--primary-color) !important;
  border: 2px solid var(--white) !important;
  border-radius: 50%;
}

input[type='checkbox'] {
  accent-color: var(--primary-color);
  height: 14px;
  width: 14px;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='checkbox']:disabled {
  accent-color: var(--secondary-color);
  height: 14px;
  width: 14px;
  box-shadow: none;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

input[type='checkbox']:disabled:before {
  border-color: #b1b5bb;
  border-width: 2px;
  border-radius: 3px;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  content: '';
  margin-left: -1px;
  background-color: #ddd;
}

input[type='checkbox']:checked {
  accent-color: var(--primary-color);
  height: 14px;
  width: 14px;
  box-shadow: none;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

input[type='checkbox']:before {
  border-color: #d1d5db;
  border-width: 2px;
  border-radius: 3px;
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  content: '';
  margin-left: -1px;
}

input[type='checkbox']:checked:before {
  border: none;
}

.bg-primary-translucent {
  position: relative;
}

.bg-primary-translucent::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  opacity: 5%;
}

.bg-warn-translucent {
  position: relative;
}

.bg-warn-translucent::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--warn-color);
  opacity: 5%;
}

.mat-step-icon.mat-step-icon-state-edit {
  opacity: 0.5;
}

iframe[name='intercom-banner-frame'] {
  z-index: 10 !important; // this fixes the intercom banner covering the sidebar menu
}

.no-padding-bottom-drawer .mat-horizontal-content-container {
  padding: 0px !important;
}

#f2f-floating-popup-container {
  margin-bottom: 0px;
  margin-left: 80px;
}
